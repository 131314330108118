<template>
<div>
    <v-dialog v-model="$store.state.dialog" width="800">
        <v-card flat class="card">
            <v-toolbar outlined flat color="grey lighten-4">
                <v-toolbar-title>{{$store.state.itemDetails.dialogType == 'add' ? 'إضافة' : 'تعديل'}} {{this.$route.meta.single}}</v-toolbar-title>
                <v-spacer />
                <v-btn @click="close()" icon>
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-form @submit.prevent="submit()" ref="form" v-model="valid">
                <v-container>
                    <v-card outlined>
                        <v-container>

                            <v-row>

                                <v-col cols="12" md="6">
                                    <h3 class="mb-2">الإسم الثلاثي <span class="required">*</span></h3>
                                    <v-text-field v-model="item.fullName" placeholder="الإسم الثلاثي" filled outlined hide-details="auto" :rules="[$global.state.required()]" />
                                </v-col>

                                <v-col cols="12" md="6">
                                    <h3 class="mb-2">الصفة </h3>
                                    <v-text-field v-model="item.jobTitle" placeholder="الصفة" filled outlined hide-details="auto" />
                                </v-col>

                                <v-col cols="12" md="6">
                                    <h3 class="mb-2">الجهة الزائرة </h3>
                                    <v-text-field v-model="item.visiting" placeholder="الجهة الزائرة" filled outlined hide-details="auto" />
                                </v-col>

                                <v-col cols="12" md="6">
                                    <h3 class="mb-2">الأجهزة </h3>
                                    <v-text-field v-model="item.devices" placeholder="الأجهزة" filled outlined hide-details="auto" />
                                </v-col>

                                <v-col cols="12" md="4">
                                    <h3 class="mb-2">نوع العجلة </h3>
                                    <v-text-field v-model="item.carName" placeholder="نوع العجلة" filled outlined hide-details="auto" />
                                </v-col>

                                <v-col cols="12" md="4">
                                    <h3 class="mb-2">لون العجلة </h3>
                                    <v-text-field v-model="item.carColor" placeholder="لون العجلة" filled outlined hide-details="auto" />
                                </v-col>

                                <v-col cols="12" md="4">
                                    <h3 class="mb-2">موديل العجلة </h3>
                                    <v-text-field v-model="item.carYear" placeholder="موديل العجلة" filled outlined hide-details="auto" />
                                </v-col>

                                <v-col cols="12" md="12">
                                    <h3 class="mb-2">نوع التخويل <span class="required">*</span></h3>
                                    <v-autocomplete multiple v-model="item.type" item-text="key" item-value="key" :rules="[$global.state.required()]" :items="[{key: 'بوابة الB3'},{key: 'بوابة الموظفين'} ,{key: 'بوابة VIP'},{key: 'بوابة  VIP الداخلية'},{key: 'بوابة الموظفين الداخلية'}]" placeholder="نوع التخويل" filled outlined hide-details="auto" />
                                </v-col>

                                <v-col cols="12" md="6">
                                    <h3 class="mb-2">ملاحظة </h3>
                                    <v-text-field v-model="item.note" placeholder="ملاحظة" filled outlined hide-details="auto" />
                                </v-col>

                            </v-row>

                        </v-container>
                    </v-card>
                    <v-col cols="12" md="12">
                        <v-row class="mt-2">
                            <v-btn :disabled="!valid" type="submit" :loading="$global.state.loading" x-large color="primary" class="btn">{{ $t("save") }}</v-btn>
                            <v-btn @click="close()" x-large color="error" class="mr-2 btn" outlined>{{ $t("cancel") }}</v-btn>
                        </v-row>
                    </v-col>
                </v-container>
            </v-form>

        </v-card>
    </v-dialog>
</div>
</template>

<script>
export default {

    data() {
        return {
            item: {},
            valid: true,
        }
    },

    mounted() {
        this.reset()

        this.$eventBus.$on('fill-fields', () => {
            console.log(this.$store.state.itemDetails)
            this.item = this.$store.state.itemDetails
        })
    },

    methods: {

        reset() {
            this.item = {
                "fullName": "",
                "jobTitle": "",
                "visiting": "",
                "devices": "",
                "carName": "",
                "carColor": "",
                "carYear": "",
                "type": "",
                "isReject": false,
                "note": ""
            }
        },

        submit() {

            if (this.$store.state.itemDetails.dialogType == 'add') {
                this.addItem()
            } else {
                this.editItem()
            }
        },

        addItem() {
            this.$eventBus.$emit('save-item', this.item)
            this.close()
        },

        editItem() {
          this.item.index = this.$store.state.itemDetails.index
            this.$eventBus.$emit('edit-item', this.item)
            this.close()
        },
        close() {
            this.$store.commit('setDialog')
            this.reset()
            this.$store.commit('setItemDetails', {})
            this.resetValidation()

        },

        resetValidation() {
            this.$refs.form.resetValidation()
        },
    },

}
</script>
